import React from "react";
import pageStyle from "../../assets/css/page.module.css";
import workStyle from "../../assets/css/work.module.css";
import style from "../../assets/css/main.module.css";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import HeroPage from "../../components/hero.page";
import Hamburger from "../../components/hamburger";
import SEO from "../../components/seo"


import imgHeader from "../../assets/img/realisations/toyota_0.jpg";
import { Link } from "gatsby";

export default () => (
  <div id="top">
    <Menu />
    <SEO 
    title="Toyota Belgium | Agence digitale | MDS Digital Agency"
    description="Chaque jour une information actualisée, une optimisation du référencement naturel et un fonctionnement sans faille pour Toyota Belgium."
    keywords="toyota belgium"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgHeader} />
    <div className={workStyle.info}>

      <div className={workStyle.info_cell}>
        <h3>Services</h3>
<div className={workStyle.big_cell}>
          <div className={workStyle.sub_cell}>
          <span className={workStyle.badge}><Link to="/services/mise-a-jour-contenu/">Mise à jour de contenu</Link></span>
          <span className={workStyle.badge}><Link to="/services/copywriting/">Copywriting</Link></span>
          <span className={workStyle.badge}><Link to="/services/referencement/">Référencement</Link></span>
          <span className={workStyle.badge}><Link to="/services/developpement/">Développement</Link></span>
         

         </div>
</div>
       
      </div>

      <div className={workStyle.info_cell}>
        <h3>Secteur</h3>
        <p>Automobile</p>
        <h3 className={workStyle.top_spacing}>Localisation</h3>
        <p>Zaventem</p>
      </div>
      <div className={workStyle.info_cell}>
        <h3>Lien </h3>
        <p>
          <a className={workStyle.external_link} href="http://www.toyota.be" target="_blank" rel="noopener noreferrer">toyota.be</a>
        </p>
      </div>
    </div>

    <article className={pageStyle.article}>
      <h2>Toyota Belgium</h2>
      <p>
      Nous <strong>maintenons à jour quotidiennement</strong> le site national, le portail clients et les sites des concessionnaires <strong>Toyota en Belgique et au Luxembourg</strong>.
      </p>

      <p>Chaque nouvelle information, image, brochure, donnée produit passe entre nos mains. Nous veillons à ce que les sites Toyota soient actualisés en permanence.</p>


      <p>
      MDS soigne également le copywriting et l’optimisation du référencement naturel de tout le contenu ajouté sur les sites.
      </p>
      <p>
      Nous veillons au bon fonctionnement des sites et nous développons de nouvelles fonctionnalités afin d’améliorer sans cesse l’expérience des utilisateurs.
      </p>

      <div className={pageStyle.cta}>
      <Link to="/contact/">Contactez-nous pour en savoir plus</Link>
      </div>
    </article>
    <Footer />
  </div>
);
